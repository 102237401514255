import {createBrowserRouter} from "react-router-dom";
import App from "./app";
import EntitiesPage, {loader as entitiesLoader} from "./pages/entities/entities";
import DocumentsPage, {loader as documentsLoader} from "./pages/documents/documents";
import WhitelistPage, {loader as whitelistLoader} from "./pages/whitelist/whitelist";
import ErrorPage from "./pages/error/error";
import React from "react";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {path: '/entities', element: <EntitiesPage/>, loader: entitiesLoader},
      {path: '/documents/:entity_code', element: <DocumentsPage/>, loader: documentsLoader},
      {path: '/whitelist', element: <WhitelistPage/>, loader: whitelistLoader},
    ]
  },
  {path: '/error/:error_id', element: <ErrorPage/>},
]);