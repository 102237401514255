import React, {ReactElement, useState} from "react";
import Table from "../../components/table/table";
import Card from "../../components/card/card"
import Button from "../../components/button/button"
import {useLoaderData, useNavigate} from "react-router-dom";
import {BackendAPI, Entity} from "../../services/rest"
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import {toast} from 'react-toastify';
import css from "./entities.module.scss";
// This date picker is nice, but we have to use a fork to support react 17+.
// Original docs here https://kiarash-z.github.io/react-modern-calendar-datepicker/docs

const api = new BackendAPI();

export function loader(): Promise<Array<any>> {
    return api.getEntities();
}

function objToDate(obj: any): string {
    return `${obj.year}-${String(obj.month).padStart(2, '0')}-${String(obj.day).padStart(2, '0')}`
}

export default function EntitiesPage(): ReactElement {
    const navigate = useNavigate();
    const initialEntities = useLoaderData() as Entity[];
    const [entityCode, setEntityCode] = useState("");
    const [entities, setEntities] = useState(initialEntities);
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
    });
    const requestIsDisabled = entityCode.length === 0 ||
        selectedDayRange.to === null ||
        selectedDayRange.from === null;

    // const onRequestAccess = () => api.requestAccess(entityCode, objToDate(selectedDayRange.from), objToDate(selectedDayRange.to))
    //     .then(entities => setEntities(entities))
    //     .then(() => toast('Access Requested!'))
    //     .catch(err => toast(err))
    //     .finally(() => {
    //         setEntityCode("")
    //         setSelectedDayRange({from: null, to: null})
    //     })

    const onRequestAccess = () => toast.promise(
        api.requestAccess(entityCode, objToDate(selectedDayRange.from), objToDate(selectedDayRange.to)),
        {
            pending: {
                render: () => "Requesting Access"
            },
            success: {
                render: () => "Access Requested"
            },
            error: {
                render: ({data}) => `${data}`
            }
        })
        .then(entities => setEntities(entities))
        .catch(err => console.error(err))
        .finally(() => {
            setEntityCode("")
            setSelectedDayRange({from: null, to: null})
        })

    return <div className={css.Container}>
        {
            entities.length > 0 ? <Card>
                <div style={{fontSize: 23, fontWeight: 700, marginBottom: 20}}>Entities</div>
                <Table>
                    <thead>
                    <tr>
                        <th>Code</th>
                        <th>Entity Name</th>
                        <th>Documents</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        entities.map(({entity_code, entity_name, access_status}) => (
                            <tr key={entity_code}>
                                <td>{entity_code}</td>
                                <td>{entity_name}</td>
                                <td>
                                    <Button onClick={() => navigate(`/documents/${entity_code}`)}
                                            disabled={access_status === 'Pending'}>
                                        {access_status === 'Pending' ? "Pending Grant" : "View Documents"}
                                    </Button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </Table>
            </Card> : null
        }
        <Card>
            <div style={{fontSize: 23, fontWeight: 700, marginBottom: 20}}>Request Entity Access</div>
            <div style={{display: "flex", flexDirection: "column", width: 250, gap: 5}}>
                <span style={{fontWeight: "lighter"}}>Enter the entity code for your auditee:</span>
                <input value={entityCode}
                       onChange={e => setEntityCode(e.target.value)}
                       style={{padding: 10}}/>
            </div>

            <div style={{fontWeight: "lighter", marginTop: 20, width: 250}}>
                Select time period to audit:
            </div>
            <DatePicker
                inputClassName={css.DatePickerInput}
                value={selectedDayRange}
                onChange={setSelectedDayRange}
                inputPlaceholder="Time Period"
            />
            <div style={{marginTop: 20, width: 250, textAlign: 'center'}}>
                <Button
                    onClick={onRequestAccess}
                    disabled={requestIsDisabled}>
                    Request Access
                </Button>
            </div>
        </Card>
    </div>
}