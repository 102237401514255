import React, {ReactElement} from "react";
import css from "./sidebar.module.scss";
import {useAuth0} from "@auth0/auth0-react";


export default function SideBar(): ReactElement {
    const {logout} = useAuth0();

    const onLogout = () => {
        localStorage.removeItem('accessToken')
        logout({logoutParams: {returnTo: window.location.origin}});
    };

    return <div className={css.SideBar}>
        <img src={'/logo.svg'} style={{width: 100}} alt={'Treasury Spring Logo'}/>
        <div className={css.UserButton} onClick={onLogout}>
            Logout
        </div>
    </div>
}
