import React, {ReactElement} from "react";
import css from "./loader.module.scss"

export function Loader(): ReactElement {
  return <div className={css.loaderWrapper}>
    <div className={css.loader}></div>
  </div>
}

export function LoaderPage(): ReactElement {
  return <div className={css.loaderPageWrapper}>
    <div className={css.loader}></div>
  </div>
}