import React, {ReactElement, Fragment, useEffect} from "react";
import SideBar from "./components/sidebar/sidebar";
import {Outlet, useNavigate, useNavigation} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {BackendAPI} from "./services/rest";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Loader, LoaderPage} from "./components/loader/loader";

const api = new BackendAPI();


export default function App(): ReactElement {
  const navigation = useNavigation();
  const navigate = useNavigate();
  const {loginWithRedirect, isAuthenticated, getAccessTokenSilently, isLoading} = useAuth0();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      getAccessTokenSilently()
        .then(token => {
          localStorage.setItem('accessToken', token)
          console.log('about to check whitelist')
          return api.checkWhitelist();
        })
        .then(({is_whitelisted}) => {
          console.log('checked whitelist', is_whitelisted)

          if (is_whitelisted) {
            // Once authed and whitelisted, go to protected route.
            // whitelist and root get redirected if the user is allowed.
            if (['/whitelist', '/'].includes(window.location.pathname)) navigate('/entities');
            else navigate(window.location.pathname);
          } else {
            navigate('/whitelist');
          }
        })
        .catch(console.error);
    }
  }, [getAccessTokenSilently, isAuthenticated, isLoading, navigate]);

  if (isLoading) { // Needed because we get isAuthenticated before Auth0 has loaded.
    return <LoaderPage/>;
  }

  if (!isAuthenticated) {
    loginWithRedirect().catch(console.error);
    return <LoaderPage/>;
  }


  return <Fragment>
    <div style={{
      height: "calc(100vh - 16px)",
      display: "flex",
      gap: 8,
      alignItems: 'stretch',
      flexDirection: 'column',
      padding: 8
    }}>
      <SideBar/>
      <div style={{
        overflow: "auto",
        backgroundColor: '#F6F7F8',
        flex: 1,
        borderRadius: 10,
        padding: 20,
        fontFamily: "Circular, sans serif"
      }}>
        {navigation.state === "loading" ? <Loader/> : <Outlet/>}
      </div>
    </div>
    <ToastContainer position="bottom-right"/>
  </Fragment>;
}