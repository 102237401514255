export interface Entity {
  entity_code: string;
  entity_name: string;
  access_status: string;
}

export interface Document {
  document_id: string;
  document_name: string;
  document_date: string;
  access_status: string;
}

export interface DocumentPageData {
  entity_name: string,
  documents: Document[]
}

export interface Whitelisted {
  is_whitelisted: boolean;
}

export class BackendAPI {
  base_url: string = process.env.REACT_APP_BACKEND_URL;

  get accessToken() {
    return localStorage.getItem('accessToken');
  }

  async requestAccess(entityCode: string, from_date: string, to_date: string): Promise<Entity[]> {
    const result = await fetch(
      `${this.base_url}/entities/${entityCode}/request`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({from_date, to_date})
      }
    );

    switch (result.status) {
      case 200:
        return await result.json();
      case 404:
        return Promise.reject('No documents are available in that date range.');
      case 500:
        const {detail} = await result.json();
        window.location.pathname = `/error/${detail}`
        break
      default:
        return Promise.reject('Internal Server Error');
    }


  }

  async getEntities(): Promise<Entity[]> {
    const result = await fetch(`${this.base_url}/entities`,
      {headers: {Authorization: `Bearer ${this.accessToken}`}});

    switch (result.status) {
      case 200:
        return await result.json();
      case 404:
        return Promise.reject('No accessible entities found.');
      case 500:
        const {detail} = await result.json();
        window.location.pathname = `/error/${detail}`
        break
      default:
        return Promise.reject('Internal Server Error');
    }
  }

  async getDocuments(entityCode: string): Promise<DocumentPageData> {
    const result = await fetch(`${this.base_url}/entities/${entityCode}/documents`,
      {headers: {Authorization: `Bearer ${this.accessToken}`}});
    switch (result.status) {
      case 200:
        return await result.json();
      case 500:
        const {detail} = await result.json();
        window.location.pathname = `/error/${detail}`
        break
      default:
        return Promise.reject('Internal Server Error');
    }
  }

  async getDocument(documentId: string, documentName: string) {
    const result = await fetch(
      `${this.base_url}/documents/${documentId}`,
      {headers: {Authorization: `Bearer ${this.accessToken}`}}
    );

    switch (result.status) {
      case 200:
        const file = await result.text();
        // const fileURL = URL.createObjectURL(file);

        // This approach allows us to open in a new window but cannot use a useful filename.
        // const pdfWindow = window.open();
        // pdfWindow.location.href = fileURL;

        const link = document.createElement('a');
        link.href = 'data:image/pdf;base64,' + file;
        link.download = documentName;
        link.target = '_blank'
        link.click();
        break;
      case 404:
        return Promise.reject('Document not found.');
      case 500:
        const {detail} = await result.json();
        window.location.pathname = `/error/${detail}`
        break
      default:
        return Promise.reject('Internal Server Error');
    }
  }

  async checkWhitelist(): Promise<Whitelisted> {
    const result = await fetch(`${this.base_url}/whitelist/check`,
      {headers: {Authorization: `Bearer ${this.accessToken}`}});

    switch (result.status) {
      case 200:
        return await result.json();
      case 500:
        const {detail} = await result.json();
        window.location.pathname = `/error/${detail}`
        console.log('rejecting in whitelist')
        return Promise.reject('Internal Server Error');
      default:
        return Promise.reject('Internal Server Error');
    }
  }

  async requestWhitelist(): Promise<Whitelisted> {
    const result = await fetch(`${this.base_url}/whitelist/request`,
      {method: 'POST', headers: {Authorization: `Bearer ${this.accessToken}`}});
    switch (result.status) {
      case 200:
        return await result.json();
      case 500:
        const {detail} = await result.json();
        window.location.pathname = `/error/${detail}`
        break
      default:
        return Promise.reject('Internal Server Error');
    }
  }
}