import "./styles/default/styles.scss";
import React from "react";
import {Auth0Provider} from '@auth0/auth0-react';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {router} from "./router";


const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: "openid profile email",
            }}
        >
            <RouterProvider router={router}/>
        </Auth0Provider>
    </React.StrictMode>
);

