import React, {ReactElement} from "react";
import {useParams} from "react-router";
import Button from "../../components/button/button";

export default function ErrorPage(): ReactElement {
  let {error_id} = useParams();

  const mailto = `mailto:xxx@treasuryspring.com?subject=Treasury Spring Audit Error ${error_id}`

  return <div style={{
    display: "flex", flexDirection: 'row',
    gap: 20, padding: 20, justifyContent: "center", alignItems: "center", height: 'calc(100vh - 40px)',
    fontFamily: "Circular, sans serif", textAlign: 'center'
  }}>
    <div style={{width: 500, backgroundColor: 'white', borderRadius: 12, padding: 20}}>
      <div style={{fontSize: 23, fontWeight: 700, marginBottom: 20}}>
        An Error has Occurred
      </div>

      <div>
        Please try pressing the button below or contacting Treasury Spring support at &nbsp;
        <a href={mailto}>
          xxx@treasuryspring.com
        </a>.
        <br/>
      </div>
      <br/><br/>
      <Button onClick={() => window.location.pathname = '/'}>Reload Page</Button>
    </div>
  </div>
}