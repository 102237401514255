import React, {ReactElement} from "react";
import Card from "../../components/card/card"
import {BackendAPI} from "../../services/rest";

const api = new BackendAPI();

export async function loader() {
    const {is_whitelisted} = await api.checkWhitelist();
    if (!is_whitelisted) return api.requestWhitelist();
    else return {is_whitelisted}
}

export default function WhitelistPage(): ReactElement {
    return <div style={{display: "flex", flexDirection: 'column', gap: 20}}>
        <Card>
            <div style={{fontSize: 23, fontWeight: 700, marginBottom: 20}}>
                Email Authorization
            </div>

            <div>
                Your email domain is waiting to be verified by our admin team, you will receive an email when you are
                accepted.
            </div>

        </Card>
    </div>
}